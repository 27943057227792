// src/Home.js
import React from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import "./App.css"; // Import custom styles
import Footer from "./Footer";

const Home = () => {
  return (
    <div className="page_div">
      <div className="menu_div">
        <Menu />
      </div>

      <div className="content_div">
        <h1 style={{ margin: "100px" }}>Welcome :)</h1>
      </div>
    </div>
  );
};

export default Home;
