import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";
import { Container, Row, Col } from "react-bootstrap";
import CollapsibleTab from "./CollapsibleTab";

const FickCalculator = () => {
  // Define state variables for inputs and outputs
  const [hb, setHb] = useState("");
  const [sao2, setSao2] = useState("");
  const [pao2, setPao2] = useState("");
  const [bsa, setBsa] = useState(""); // Body Surface Area (direct input)
  const [age, setAge] = useState(""); // Age input
  const [height, setHeight] = useState(""); // Height input (when in BSA calculation mode)
  const [weight, setWeight] = useState(""); // Weight input (when in BSA calculation mode)
  const [isBsaMode, setIsBsaMode] = useState(true); // Toggle between BSA input modes
  const [cardiacOutput, setCardiacOutput] = useState(null);
  const [cardiacIndex, setCardiacIndex] = useState(null);

  // Function to handle the calculation
  const calculateCardiacMetrics = () => {
    // Convert inputs to numbers
    const hbValue = parseFloat(hb);
    const sao2Value = parseFloat(sao2);
    const pao2Value = parseFloat(pao2);
    const bsaValue = parseFloat(bsa) || 0;
    const ageValue = parseFloat(age);
    const heightValue = parseFloat(height) || 0;
    const weightValue = parseFloat(weight) || 0;

    // Validate inputs
    if (
      isNaN(hbValue) ||
      isNaN(sao2Value) ||
      isNaN(pao2Value) ||
      isNaN(ageValue) ||
      (isBsaMode && isNaN(bsaValue)) ||
      (!isBsaMode && (isNaN(heightValue) || isNaN(weightValue))) ||
      (isBsaMode && bsaValue <= 0) ||
      (!isBsaMode && (heightValue <= 0 || weightValue <= 0)) ||
      ageValue <= 0
    ) {
      alert("Please enter valid numbers for all fields.");
      return;
    }

    // Calculate BSA if in height/weight mode
    const calculatedBsaValue = isBsaMode
      ? bsaValue
      : 0.007184 * Math.pow(weightValue, 0.425) * Math.pow(heightValue, 0.725);

    // Calculate CaO2 and CvO2
    const cao2Value = (hbValue * sao2Value * 1.34 + 0.304) * 10;
    const cvo2Value = (hbValue * pao2Value * 1.34 + 0.304) * 10;

    // Calculate VO2 based on age
    const vo2Value =
      ageValue >= 70 ? 110 * calculatedBsaValue : 125 * calculatedBsaValue;

    // Calculate Cardiac Output (CO)
    if (cao2Value <= cvo2Value) {
      alert(
        "Arterial oxygen content must be greater than venous oxygen content."
      );
      return;
    }
    const output = vo2Value / (cao2Value - cvo2Value);

    // Calculate Cardiac Index
    const index = output / calculatedBsaValue;

    // Set the results
    setCardiacOutput(output.toFixed(1));
    setCardiacIndex(index.toFixed(1));
  };

  const toggleBsaMode = () => {
    setIsBsaMode(!isBsaMode);
    if (isBsaMode) {
      // Reset height and weight when switching to BSA mode
      setHeight("");
      setWeight("");
    } else {
      // Reset BSA when switching to height/weight mode
      setBsa("");
    }
  };

  const links = [
    {
      href: "https://shop.elsevier.com/books/textbook-of-clinical-hemodynamics/ragosta/978-0-323-48042-0",
      text: "Textbook of clinical Hemodynamics, Ragosta",
    },
  ];

  return (
    <div className="page_div">
      <div className="menu_div">
        <Menu />
      </div>

      <div className="content_div">
        <h1 className="title">Cardiac Output Calculator</h1>

        <div className="row mb-3">
          <div className="col-md-6">
            {/* Conditionally formatted BSA label */}
            <label htmlFor="bsa" className="form-label">
              {isBsaMode ? (
                <>
                  Enter or{" "}
                  <button className="btn-fit-text" onClick={toggleBsaMode}>
                    Calculate
                  </button>{" "}
                  Body Surface Area (BSA) [m²]:
                </>
              ) : (
                <>
                  <button className="btn-fit-text" onClick={toggleBsaMode}>
                    Enter
                  </button>{" "}
                  or Calculate Body Surface Area (BSA):
                </>
              )}
            </label>
            {isBsaMode ? (
              <input
                type="number"
                id="bsa"
                className="form-control"
                value={bsa}
                onChange={(e) => setBsa(e.target.value)}
                placeholder="Enter BSA (m²)"
              />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="number"
                      id="height"
                      className="form-control"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                      placeholder="Height (cm):"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      id="weight"
                      className="form-control"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      placeholder="Weight (kg):"
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Column for Age and Hemoglobin */}
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="age" className="form-label">
                  Age:
                </label>
                <input
                  type="number"
                  id="age"
                  className="form-control"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  placeholder="Age"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="hb" className="form-label">
                  Hemoglobin (Hb):
                </label>
                <input
                  type="number"
                  id="hb"
                  className="form-control"
                  value={hb}
                  onChange={(e) => setHb(e.target.value)}
                  placeholder="g/dL"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="pao2" className="form-label">
              Mixed Venous O₂ Saturation:
            </label>
            <input
              type="number"
              id="pao2"
              className="form-control"
              value={pao2}
              onChange={(e) => setPao2(e.target.value)}
              placeholder="eg 0.65"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="sao2" className="form-label">
              Arterial O₂ Saturation:
            </label>
            <input
              type="number"
              id="sao2"
              className="form-control"
              value={sao2}
              onChange={(e) => setSao2(e.target.value)}
              placeholder="eg 0.98"
            />
          </div>
        </div>

        <button className="calculate-button" onClick={calculateCardiacMetrics}>
          Calculate
        </button>

        {cardiacOutput !== null && (
          <div className="mt-3">
            <h4>
              Cardiac Output: <strong>{cardiacOutput} L/min</strong>
            </h4>
            <h4>
              Cardiac Index: <strong>{cardiacIndex} L/min/m²</strong>
            </h4>
          </div>
        )}

        <div className="mt-4">
          <Link to="/" className="btn btn-secondary">
            Home
          </Link>
        </div>
      </div>

      <div className="page-container">
        <CollapsibleTab links={links} />
      </div>
    </div>
  );
};

export default FickCalculator;
