// src/Menu.js
import React from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Custom styles (optional)

const Menu = () => {
  return (
    <div className="menu-container">
      <nav className="menu">
        <ul className="menu-list">
          <li className="menu-item">
            <Link to="/metscalculator" className="menu-link">
              METs Calculator
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/ecv-calculator" className="menu-link">
              Myocardial ECV Calculator
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/guideline-assistant" className="menu-link">
              Guideline Assistant
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/fickcalculator" className="menu-link">
              Fick Calculator
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/cmrflow" className="menu-link">
              CMR Flows
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/tmgradient" className="menu-link">
              Transmitral Gradients
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/prostheticvalves" className="menu-link">
              Prosthetic Valves
            </Link>
          </li>
          <li className="menu-item"></li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
