// src/ECVCalculator.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";
import CollapsibleTab from "./CollapsibleTab";

const ECVCalculator = () => {
  const [preContrastT1Myocardium, setPreContrastT1Myocardium] = useState("");
  const [postContrastT1Myocardium, setPostContrastT1Myocardium] = useState("");
  const [preContrastT1Bloodpool, setPreContrastT1Bloodpool] = useState("");
  const [postContrastT1Bloodpool, setPostContrastT1Bloodpool] = useState("");
  const [hematocrit, setHematocrit] = useState("");
  const [ecv, setEcv] = useState(null);

  const calculateECV = () => {
    const preT1M = parseFloat(preContrastT1Myocardium);
    const postT1M = parseFloat(postContrastT1Myocardium);
    const preT1B = parseFloat(preContrastT1Bloodpool);
    const postT1B = parseFloat(postContrastT1Bloodpool);
    const hct = parseFloat(hematocrit);

    if (
      isNaN(preT1M) ||
      isNaN(postT1M) ||
      isNaN(preT1B) ||
      isNaN(postT1B) ||
      isNaN(hct)
    ) {
      alert("Please enter valid numbers for all fields.");
      return;
    }

    // Convert T1 values to R1 values
    const preR1M = 1 / preT1M;
    const postR1M = 1 / postT1M;
    const preR1B = 1 / preT1B;
    const postR1B = 1 / postT1B;

    // Calculate deltaR1M and deltaR1B
    const deltaR1M = postR1M - preR1M;
    const deltaR1B = postR1B - preR1B;

    // Calculate ECV
    const calculatedECV = (deltaR1M / deltaR1B) * (1 - hct);
    setEcv((calculatedECV * 100).toFixed(1)); // Convert to percentage and format to one decimal place
  };

  const links = [
    {
      href: "https://www.ahajournals.org/doi/10.1161/CIRCULATIONAHA.109.930636",
      text: "Equilibrium Contrast Cardiovascular Magnetic Resonance for the Measurement of Diffuse Myocardial Fibrosis",
    },
  ];

  return (
    <div className="page_div">
      <div className="menu_div">
        <Menu />
      </div>

      <div className="content_div">
        <h1 className="title">Myocardial ECV Calculator</h1>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="preContrastT1Myocardium" className="form-label">
              Pre-contrast T1, Myocardium:
            </label>
            <input
              type="number"
              id="preContrastT1Myocardium"
              className="form-control"
              value={preContrastT1Myocardium}
              onChange={(e) => setPreContrastT1Myocardium(e.target.value)}
              placeholder="ms"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="preContrastT1Bloodpool" className="form-label">
              Pre-contrast T1, Bloodpool:
            </label>
            <input
              type="number"
              id="preContrastT1Bloodpool"
              className="form-control"
              value={preContrastT1Bloodpool}
              onChange={(e) => setPreContrastT1Bloodpool(e.target.value)}
              placeholder="ms"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="postContrastT1Myocardium" className="form-label">
              Post-contrast T1, Myocardium:
            </label>
            <input
              type="number"
              id="postContrastT1Myocardium"
              className="form-control"
              value={postContrastT1Myocardium}
              onChange={(e) => setPostContrastT1Myocardium(e.target.value)}
              placeholder="ms"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="postContrastT1Bloodpool" className="form-label">
              Post-contrast T1, Bloodpool:
            </label>
            <input
              type="number"
              id="postContrastT1Bloodpool"
              className="form-control"
              value={postContrastT1Bloodpool}
              onChange={(e) => setPostContrastT1Bloodpool(e.target.value)}
              placeholder="ms"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="hematocrit" className="form-label">
            Hematocrit:
          </label>
          <input
            type="number"
            id="hematocrit"
            className="form-control"
            value={hematocrit}
            onChange={(e) => setHematocrit(e.target.value)}
            placeholder="eg 0.45"
          />
        </div>
        <button className="calculate-button" onClick={calculateECV}>
          Calculate ECV
        </button>
        {ecv !== null && (
          <div className="mt-3">
            <h4>
              Calculated Myocardial ECV: <strong>{ecv}%</strong>
            </h4>
          </div>
        )}
        <div className="mt-4">
          <Link to="/" className="btn btn-secondary">
            Home
          </Link>
        </div>
      </div>

      <div className="page-container">
        <CollapsibleTab links={links} />
      </div>
    </div>
  );
};

export default ECVCalculator;
