// src/ProjectedMitralGradient.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./App.css"; // Import custom styles
import Menu from "./Menu"; // Import the Menu component
import Footer from "./Footer";
import CollapsibleTab from "./CollapsibleTab";

const links = [
  {
    href: "https://www.jacc.org/doi/10.1016/j.jcmg.2020.12.013",
    text: "A Novel Assessment Using Projected Transmitral Gradient Improves Diagnostic Yield of Doppler Hemodynamics in Rheumatic and Calcific Mitral Stenosis",
  },
];
const ProjectedMitralGradient = () => {
  const [isMale, setIsMale] = useState(true); // true for male, false for female
  const [heartRate, setHeartRate] = useState("");
  const [strokeVolume, setStrokeVolume] = useState("");
  const [projectedTMG, setProjectedTMG] = useState(null);
  const [tmg, setTmg] = useState(0); // Initialize TMG as needed

  const calculateProjectedTMG = () => {
    const hr = parseFloat(heartRate);
    const sv = parseFloat(strokeVolume);
    let adjustment = 0;

    if (isMale) {
      adjustment = 0.07 * (hr - 70) + 0.03 * (sv - 97);
    } else {
      adjustment = 0.08 * (hr - 72) + 0.04 * (sv - 84);
    }

    const result = tmg - adjustment;
    setProjectedTMG(result);
  };

  return (
    <div className="page_div">
      <div className="menu_div">
        <Menu />
      </div>

      <div className="content_div">
        <h1 className="title">Projected Transmitral Gradient Calculator</h1>
        <div className="mb-3">
          <label className="form-label">Gender:</label>
          <div>
            <button
              className={`gender-button ${isMale ? "selected" : "unselected"}`}
              onClick={() => setIsMale(true)}
            >
              Male
            </button>
            <button
              className={`gender-button ${!isMale ? "selected" : "unselected"}`}
              onClick={() => setIsMale(false)}
            >
              Female
            </button>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="tmg" className="form-label">
            Measured Transmitral Gradient (mmHg):
          </label>
          <input
            type="number"
            id="tmg"
            className="form-control"
            value={tmg}
            onChange={(e) => setTmg(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="heartRate" className="form-label">
            Heart Rate (bpm):
          </label>
          <input
            type="number"
            id="heartRate"
            className="form-control"
            value={heartRate}
            onChange={(e) => setHeartRate(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="strokeVolume" className="form-label">
            Stroke Volume (ml):
          </label>
          <input
            type="number"
            id="strokeVolume"
            className="form-control"
            value={strokeVolume}
            onChange={(e) => setStrokeVolume(e.target.value)}
          />
        </div>
        <button className="calculate-button" onClick={calculateProjectedTMG}>
          Calculate Projected Transmitral Gradient
        </button>

        {projectedTMG !== null && (
          <div className="mt-3">
            <h4>
              Projected Transmitral Gradient:{" "}
              <strong>{projectedTMG.toFixed(2)} mmHg</strong>
            </h4>
          </div>
        )}
        <div className="mt-4">
          <Link to="/" className="btn btn-secondary">
            Home
          </Link>
        </div>
      </div>

      <div className="page-container">
        <CollapsibleTab links={links} />
      </div>
    </div>
  );
};
export default ProjectedMitralGradient;
